<template>
  <v-container text-xs-center fluid>
    <v-card rounded-pill width="1100px" class="pa-1 mb-1 mx-auto" outlined color="transparent">
    <v-form>
      <v-layout wrap >
        <v-select dense outlined rounded class="ma-1" style="height: 40px; width: 150px;"
          id="company" 
          ref="company" 
          label="경영체" 
          v-model="company"
          :items="companyItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다.."
          item-text="name"
          return-object
          @change="changeCompany()"
          @focus="comboCompanies()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 150px;" 
          id="farm" 
          ref="farm" 
          label="농장/사업장✽" 
          v-model="farm"
          :items="farmItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeFarm()"
          @focus="comboFarms()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 150px;"
          id="building" 
          ref="building" 
          label="축사/건물/구역" 
          v-model="building"
          :items="buildingItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          placeholder="선택않으면 농장/사업장전체를 모니터합니다"
          item-text="name"
          return-object
          @change="changeBuilding()"
          @focus="comboBuildings()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
              {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 150px;"
          id="room" 
          ref="room" 
          label="돈방/위치✽" 
          v-model="room"
          :items="roomItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeRoom()"
          @focus="comboRooms()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
      </v-layout>
      <v-layout wrap class="layout">
        <v-sheet class="timebar ml-2" min-width="350px"
        >
          <v-slider 
            :loading="loading" 
            label="간격"
            color="#3a4f3f"
            track-color="gray"
            v-model="term"
            :tick-labels="['1분', '1시간', '일별']"
            :max="2"
            step="1"
            ticks="always"
            tick-size="5"
            @change="refreshList()"
          ></v-slider>
        </v-sheet>
        
        <div class="ml-5"></div>

        <v-layout wrap class="change_btn" >
          <v-btn
            :disabled = "showList"
            class="ml-8 ma-2 show__btn"
            @click="showList = true; refreshList()"
          >
            목록
            <v-icon right dark>mdi-view-list</v-icon>
          </v-btn>
          <v-btn
            :disabled = "!showList"
            class="ma-2 show__btn"
            @click="showList = false; refreshList()"
          >
            차트
            <v-icon right dark>mdi-chart-line</v-icon>
          </v-btn>
        </v-layout>

        <v-spacer></v-spacer>

        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field dense outlined style="height:40px; width:140px" class="calendar shrink rounded mt-1 ml-1 mb-1"
              id="fr_date" 
              ref="fr_date" 
              v-model="fr_date"
              label="부터"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title locale="ko-KR"
            v-model="fr_date"
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field dense outlined style="height:40px; width:140px" class="calendar shrink rounded mt-1 ml-2  mb-1 mr-1"
              id="to_date" 
              ref="to_date" 
              v-model="to_date"
              label="까지"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title locale="ko-KR"
            v-model="to_date"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
        <v-btn class="elevation-5 ma-1 rounded normal-btn" style="height: 40px; width: 70px"
          id="refreshList" 
          :loading="loading" 
          ref="refreshList" 
          type="submit" 
          @click.prevent="refreshList()">
          <img src="@/assets/icon_search.svg" alt="새로고침" />
        </v-btn>
      </v-layout>
    </v-form>
    </v-card>

    <v-layout justify-center >
      <v-card width="1300px"  color="transparent" style="cursor:pointer;" outlined>
        <ListDetail :items="listData" :items_daily="listData_daily" :loadingTable="loading" :divisionTerm= "term" v-show="showList"  />
        <!-- <ChartDetail2 :trendData="trendData"  v-show="!showList" :loadChart="loading"/> -->
        <ChartTest2 :trendData="trendData"  v-show="!showList" :loadChart="loading" />
      </v-card>
    </v-layout>
 </v-container>
</template>

<script>
import Apis from '@/api/apis'
import VueCookies from "vue-cookies";
import dateUtil from "@/utils/custom/dateProperty.js";
import ListDetail from "./child/ListDetail.vue";
// import ChartDetail2 from "./child/ChartDetail2.vue";
import ChartTest2 from "./child/ChartTest2.vue";

const term_list = [1, 60];

export default {
  name: 'Barometer',
  components: {
    ListDetail,
    // ChartDetail,
    // ChartDetail2,
    ChartTest2
  },
  async created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
    await this.comboCompanies();
    await this.comboFarms();
    await this.comboBuildings();
    await this.comboRooms();

    let cdate = new Date();
    this.to_date= dateUtil.format(cdate, "yyyy-MM-dd");
    let frdate = new Date(cdate.setDate(cdate.getDate() - 6));
    this.fr_date= dateUtil.format(frdate, "yyyy-MM-dd");

    let cookie_info = VueCookies.get("barometer_info");
    if (cookie_info) {
      this.showList = cookie_info.showList;
      this.term = 1;
    }
    if (!this.isNull(this.$route.params)) {
      // console.log("Params : ", this.$route.params);
      this.company = this.$route.params.company;
      this.farm = this.$route.params.farm;
      this.building = this.$route.params.building;
      this.room = this.$route.params.room;
    } else if (cookie_info) {
          this.company = cookie_info["company"] || {};
          this.farm = cookie_info.farm || {};
          this.building = cookie_info.building || {};
          this.room = cookie_info.room || {};
    } else {
      this.room = this.roomItems[0];
      this.company = {code: this.room.company_cd, name: this.room.company_name};
      this.farm = {code: this.room.farm_cd, name: this.room.farm_name};
      this.building = {code: this.room.building_cd, name: this.room.building_name};
    }
    this.refreshList();
  },
  data () {
    return {
      showList: false,
      // tabs: null,

      animateChart: false,

      loading: false,
      company : {},
      companyItems : [],
      farm : {},
      farmItems : [],
      building : {},
      buildingItems : [],
      room : {},
      roomItems : [],

      term: 1,
      fr_date: "",
      to_date: "",

      menu1: false,
      menu2: false,

      listData: [],
      listData_daily: [],

      trendData: {},
    }
  },
  methods: {

    refreshList() {
      if  (!this.room || !this.room.code ) {
        alert("돈방/위치를 선택하세요");
        this.$refs.room.focus();
        return;
      }
      if  (!this.fr_date) {
        alert("조회 시작일 선택하세요");
        this.$refs.fr_date.focus();
        return;
      }
      if  (!this.to_date) {
        alert("조회 시작일 선택하세요");
        this.$refs.to_date.focus();
        return;
      }
      this.$store.commit("resMessage","");
      this.loading = true;
      this.listData = [];
      this.listData_daily = [];

      if (this.term < 2) {
        Apis.room24hoursView({
          company_cd: this.company.code,
          farm_cd: this.farm.code,
          building_cd: this.building.code,
          room_cd: this.room.code,
          fr_dt: this.fr_date,
          to_dt: this.to_date,
          start_time: "00:00:00",
          end_time: "23:59:59",
          timeDivision: term_list[this.term],
          },(res) => {  
            if (res.result) {
              this.listData = res.data.datalist;
              // this.cDate = this.to_date.substring(5);
              // this.maxTemp = res.data.highest.temp;
              // this.minTemp = res.data.lowest.temp;
              if (!this.showList) {
                // 챠트 데이타
                // console.log("resdata",res.data.datalist);
                
                let time_temp = [];
                let temp_array = [];

                let time_hum = [];
                let hum_array = [];

                let time_co2 = [];
                let co2_array = [];

                let time_nh3 = [];
                let nh3_array = [];

                let time_h2s = [];
                let h2s_array = [];

                let wtemp_array = [];
              
                this.listData.forEach(function (item) {
                  if  (item.temp && !isNaN(item.temp)) time_temp.push(item.checkDateTime.substring(5,16));

                  if  (item.temp && !isNaN(item.temp)) temp_array.push(Number(item.temp))|| temp_array.push(null);

                  if  (item.w_temp && !isNaN(item.w_temp)) wtemp_array.push(Number(item.w_temp))|| wtemp_array.push(null);

                  if  (item.hum && !isNaN(item.hum)) time_hum.push(item.checkDateTime.substring(5,16));
                  if  (item.hum && !isNaN(item.hum)) hum_array.push(Number(item.hum))|| hum_array.push(null);

                  if  (item.co2 && !isNaN(item.co2)) time_co2.push(item.checkDateTime.substring(5,16));
                  if  (item.co2 && !isNaN(item.co2)) co2_array.push(Number(item.co2))|| co2_array.push(null);

                  if  (item.nh3 && !isNaN(item.nh3)) time_nh3.push(item.checkDateTime.substring(5,16));
                  if  (item.nh3 && !isNaN(item.nh3)) nh3_array.push(Number(item.nh3))|| nh3_array.push(null);

                  if (item.h2s !== null && item.h2s !== undefined && !isNaN(item.h2s)) time_h2s.push(item.checkDateTime.substring(5,16));
                  if (item.h2s !== null && item.h2s !== undefined && !isNaN(item.h2s))  h2s_array.push(Number(item.h2s))|| h2s_array.push(null);
                  
                }) 

                // console.log(this.listData);                
                this.trendData = {
                  term : this.term,
                  
                  time_temp : time_temp,
                  temp: temp_array,
                  
                  time_hum : time_hum,
                  hum: hum_array,

                  time_co2 : time_co2,
                  co2: co2_array,

                  time_nh3 : time_nh3,
                  nh3: nh3_array,

                  time_h2s : time_h2s,
                  h2s: h2s_array,

                  w_temp: wtemp_array
                }

                // console.log(this.trendData.w_temp);
                // console.log('nh3',this.trendData.nh3);

                // console.log('h2s',this.trendData.h2s);
              }
          
              this.$store.commit("resMessage",res.message);
              let cookie_info = { company: this.company, farm: this.farm, building: this.building, room: this.room, showList: this.showList, term: this.term };
              VueCookies.set("barometer_info" ,cookie_info,"30d");
            } else {
              alert(res.message);
            }
            this.loading = false;
            this.$refs.room.focus();
            // this.$re   fs.refreshList.focus();

          }).catch( (err) => {  // API 오류 처리
              console.log("room24hoursView API 호출 오류",err)
              alert(err);
              this.loading = false;
          }
        )
      } else {
        Apis.roomDailyView({
          company_cd: this.company.code,
          farm_cd: this.farm.code,
          building_cd: this.building.code,
          room_cd: this.room.code,
          fr_date: this.fr_date,
          to_date: this.to_date,
          },(res) => {  
            if (res.result) {
              this.listData_daily = res.data;
              if (!this.showList) {
                // console.log("daily", res.data);

                let time_array = [];
                
                let temp_time = [];
                let temp_avg = [];
                let temp_low = [];
                let temp_high = [];

                let w_temp_avg = [];
                let w_temp_low = [];
                let w_temp_high = [];

                let hum_time = [];
                let hum_avg = [];
                let hum_low = [];
                let hum_high = [];

                let co2_time = [];
                let co2_avg = [];
                let co2_low = [];
                let co2_high = [];

                let nh3_time = [];
                let nh3_avg = [];
                let nh3_low = [];
                let nh3_high = [];

                let h2s_time = [];          
                let h2s_avg = [];
                let h2s_low = [];
                let h2s_high = [];

                res.data.forEach(function (item) {
                  // console.log(res.data)
                  
                  time_array.push(item.checkDate.substring(5));

                  !isNaN(item.temp_average) && temp_time.push(item.checkDate.substring(5)) || "";
                  !isNaN(item.temp_average) && temp_avg.push( Number(item.temp_average)) || temp_avg.push(null);
                  !isNaN(item.temp_highest) && temp_high.push( Number(item.temp_highest)) || temp_high.push(null);
                  !isNaN(item.temp_lowest) && temp_low.push( Number(item.temp_lowest)) || temp_low.push(null);

                  !isNaN(item.w_temp_average) && w_temp_avg.push( Number(item.w_temp_average)) || w_temp_avg.push(null);
                  !isNaN(item.w_temp_highest) && w_temp_high.push( Number(item.w_temp_highest)) || w_temp_high.push(null);
                  !isNaN(item.w_temp_lowest) && w_temp_low.push( Number(item.w_temp_lowest)) || w_temp_low.push(null);

                  !isNaN(item.hum_average) && hum_time.push(item.checkDate.substring(5)) || "";
                  !isNaN(item.hum_average) && hum_avg.push( Number(item.hum_average)) || hum_avg.push(null);
                  !isNaN(item.hum_highest) && hum_high.push( Number(item.hum_highest)) || hum_high.push(null);
                  !isNaN(item.hum_lowest) && hum_low.push( Number(item.hum_lowest)) || hum_low.push(null);

                  !isNaN(item.co2_average) && co2_time.push(item.checkDate.substring(5)) || "";
                  !isNaN(item.co2_average) && co2_avg.push( Number(item.co2_average)) || co2_avg.push(null);
                  !isNaN(item.co2_highest) && co2_high.push( Number(item.co2_highest)) || co2_high.push(null);
                  !isNaN(item.co2_lowest) && co2_low.push( Number(item.co2_lowest)) || co2_low.push(null);

                  !isNaN(item.nh3_average) && nh3_time.push(item.checkDate.substring(5)) || "";
                  !isNaN(item.nh3_average) && nh3_avg.push( Number(item.nh3_average)) || nh3_avg.push(null);
                  !isNaN(item.nh3_highest) && nh3_high.push( Number(item.nh3_highest)) || nh3_high.push(null);
                  !isNaN(item.nh3_lowest) && nh3_low.push( Number(item.nh3_lowest)) || nh3_low.push(null);
                
                  !isNaN(item.h2s_average) && h2s_time.push(item.checkDate.substring(5)) || "";
                  !isNaN(item.h2s_average) && h2s_avg.push( Number(item.h2s_average)) || h2s_avg.push(null);
                  !isNaN(item.h2s_highest) && h2s_high.push( Number(item.h2s_highest)) || h2s_high.push(null);
                  !isNaN(item.h2s_lowest) && h2s_low.push( Number(item.h2s_lowest)) || h2s_low.push(null);

                  
                })

                this.trendData = {
                  time_array: time_array,

                  temp_time:temp_time,
                  temp_avg: temp_avg,
                  temp_low: temp_low,
                  temp_high: temp_high,

                  w_temp_avg: w_temp_avg,
                  w_temp_low: w_temp_low,
                  w_temp_high: w_temp_high,

                  hum_time:hum_time,
                  hum_avg: hum_avg,
                  hum_low: hum_low,
                  hum_high: hum_high,

                  co2_time:co2_time,
                  co2_avg: co2_avg,
                  co2_low: co2_low,
                  co2_high: co2_high,

                  nh3_time:nh3_time,
                  nh3_avg: nh3_avg,
                  nh3_low: nh3_low,
                  nh3_high: nh3_high,

                  h2s_time:h2s_time,
                  h2s_avg: h2s_avg,
                  h2s_low: h2s_low,
                  h2s_high: h2s_high,
                }
            
                // console.log(this.trendData.h2s_time)

              }  
              this.$store.commit("resMessage",res.message);
              let cookie_info = { company: this.company, farm: this.farm, building: this.building, room: this.room, showList: this.showList, term: 1 };
              VueCookies.set("barometer_info" ,cookie_info,"30d");

            } else {
              alert(res.message);
            }
            this.loading = false;
            this.$refs.room.focus();
            // this.$re   fs.refreshList.focus();

          }).catch( (err) => {  // API 오류 처리
              console.log("room24hoursView API 호출 오류",err)
              alert(err);
              this.loading = false;
          }
        )
      } 
    },
    async comboCompanies() {
      await Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
        }
      ) 
    },
    changeCompany() {   // 하위 콤보 지우기;
      this.farm = {};
      this.building = {};
      this.room = {};
    },
    async comboFarms() {
      await Apis.comboFarms({
        company_cd: this.company && this.company.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.company_cd) {
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
      }
        this.building = {};
        this.room = {};
    },
    async comboBuildings() {
      await Apis.comboBuildings({
        company_cd:this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.buildingItems = [];
            for (let i in res.data) {
            this.buildingItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              code: res.data[i].building_cd,
              name: res.data[i].building_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
        }
      ) 
    },
    changeBuilding() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.building && this.building.company_cd) {
        this.company = {code: this.building.company_cd, name: this.building.company_name};
        this.farm = {code: this.building.farm_cd, name: this.building.farm_name};
      }
        this.room = {};
    },
    async comboRooms() {
      await Apis.comboRooms({
        company_cd:this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        building_cd: this.building && this.building.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.roomItems = [];
            for (let i in res.data) {
            this.roomItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              building_cd: res.data[i].building_cd, 
              building_name: res.data[i].building_name, 
              code: res.data[i].room_cd,
              name: res.data[i].room_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboRooms API 호출 오류",err)
        }
      ) 
    },
    changeRoom() {      // 하위콤보 선택시 상위 콤보 동시 세팅
      if (this.room && this.room.company_cd) {
        this.company = {code: this.room.company_cd, name: this.room.company_name};
        this.farm = {code: this.room.farm_cd, name: this.room.farm_name};
        this.building = {code: this.room.building_cd, name: this.room.building_name};
      }
    },
    goback() { 
      window.history.back();
    },
    detailPerTimes() {
      this.$router.push({
        name: "MonitorSensor",
        params: { 
          company: this.company,
          farm: this.farm,
          building: this.building,
          room: this.room,
          }
      });
    },

  }  // -----------methods 

}
</script>

<style lang="scss" scoped>
.tab_text_green{
  color:#3a4f3f;
  font-size:14pt !important;
  font-weight: bold !important;
  // background-color: #3a4f3f !important;
}
.tab_text_red{
  color:#88191a;
  font-size:14pt !important;
  font-weight: bold !important;
  // background-color: #3a4f3f !important;
}

.show__btn{
  width:120px;
  height:40px !important;
  border-radius:20px;
  font-size:18px !important;
  letter-spacing: -2px;
  background-color:#eaeaea;
  color:#e4e4e4 !important;
  transition: all 0.3s ease 0s;
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  border: none;
  border-radius: 45px;
  background: #e4e8d4;
  background-image: linear-gradient(to top, #e4e8d4 0%, #ddefdc 100%);
  color:#3a4f3f !important;
  box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.2);
  transition: all 0.3s ease 0s;
}

.theme--light .v-btn.v-btn--has-bg {
  color:#3a4f3a9f !important;
  font-weight:700;
  letter-spacing: -0.74px;
}

.normal-btn{
  align-content: center !important;
  color:white !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.v-text-field ::v-deep input {
    font-size: 0.9em;
}

@media screen and (max-width: 768px) {
.timebar{
  width:100%;
}
.change_btn{
  justify-content: center;
}
.calendar{
  max-width:120px !important; 
}
.v-text-field ::v-deep input {
    font-size: 0.7em;
}

.normal-btn{
  width:60px !important;
  margin-left:15px !important; 
}
}
.items {
  color: #a3a1a1;
  font-size: 14px;
  // font-weight: bold;
  text-align: left;
  letter-spacing: -1.2px;
}


</style>
