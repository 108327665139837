<template>
  <v-container>
    <v-layout>
      <v-card width="100%" height="auto" class="mx-auto ml-0" outlined color="transparent">
        <!-- 시 간격별 데이타 조회   -->
        <v-data-table  dense height="550"
          id="time__table"
          v-show="divisionTerm < 2"
          fixed-header
          :footer-props="{
                // showFirstLastPage: true,
                // disableItemsPerPage: true,
                itemsPerPageOptions:[-1]
              }"
          :loading="loadingTable" 
          :headers="headers"
          :items="items"
          no-data-text="측정된 자료가 없습니다."
          class="elevation-2"
          item-key="checkDateTime"
          single-select
          @click:row="selectRow"
          :sort-by= "['checkDate','checkTime']"
          :sort-desc= "[true,true]"
          :search="search"

        >
        </v-data-table>

        <!-- 일별 데이터 조회   -->
        <v-data-table dense height="550" v-resize="onResize" 
          id="daily__table"
          v-show="divisionTerm == 2"
          fixed-header
          hide-default-footer
          :items-per-page= "1000"
          :loading="loadingTable" 
          :headers="headers_daily"
          :items="items_daily"
          no-data-text="측정된 자료가 없습니다."
          class="elevation-2"
          item-key="checkDate"
          single-select
          @click:row="selectRow"
          sort-by= "checkDate"
          :sort-desc= "true"
          :search="search"
          :class="{mobile: isMobile}"
          hide-default-header
          >
          <template v-slot:header="{ props }">
            <tr style="background-color: #436251;" align="left" v-if="!isMobile" >
            <th :style="head.lb ? 'border-left: 1px solid white;' : '' " v-for="(head, i) in props.headers"
                :key="i">
                <td class="cell_top" nowrap > {{ head.text0 }} </td>
            </th>
            </tr>
            <tr style="background-color: #436251;" align="center" v-if="!isMobile" >
            <th :style="head.lb ? 'border-left: 1px solid white;' : '' " v-for="(head, i) in props.headers"
                :key="i">
                <td class="cell_bottom">  {{ head.text }} </td>
            </th>
            </tr>
          </template>

          <template v-slot:item= "{ item }">
            <tr v-if="!isMobile">
              <td>{{ item.checkDate }}</td>
              <td >{{ item.temp_highest }}</td>
              <td >{{ item.temp_lowest }}</td>
              <td class="cell_temp">{{ item.temp_average }}</td>
              <td class="cell_feel">{{ item.feel_average }}</td>
              <td >{{ item.hum_highest }}</td>
              <td >{{ item.hum_lowest }}</td>
              <td class="cell_hum">{{ item.hum_average }}</td>
              <td class="cell_r">{{ item.co2_highest }}</td>
              <td class="cell_r">{{ item.co2_lowest }}</td>
              <td class="cell_co2">{{ item.co2_average }}</td>
              <td class="cell_r">{{ item.nh3_highest }}</td>
              <td class="cell_r">{{ item.nh3_lowest }}</td>
              <td class="cell_nh3">{{ item.nh3_average }}</td>
              <td class="cell_r">{{ item.h2s_highest }}</td>
              <td class="cell_r">{{ item.h2s_lowest }}</td>
              <td class="cell_h2s">{{ item.h2s_average }}</td>
            </tr>

            <tr v-else>
              <td >
                <div class="date mt-2">
                  <div class="flex-title"> 측정일</div>
                  <div> {{ item.checkDate }}</div>
                </div>

                <!-- <div class="line"></div> -->

                <div class="flex">
                  <div class="flex-title weight">온도</div>

                  <div class="flex">
                    <div class="flex-content">
                      <div class="text_red weight">최고</div>
                      <div class="text_red"> {{ item.temp_highest }} °C  </div>
                    </div>

                    <div class="flex-content">
                      <div class="text_blue">최저</div>
                      <div class="text_blue"> {{ item.temp_lowest }} °C   </div>
                    </div>

                    <div class="flex-content">
                      <div class="weight">평균</div>
                      <div> {{ item.temp_average }} °C   </div>
                    </div>
                  </div>

                  <div class="line"></div>

                  <div class="flex">
                    <div class="flex-title weight">체감온도</div>

                    <div class="flex">
                      <div class="flex-content">
                        <div class="weight">평균</div>
                        <div>  {{ item.feel_average }}°C  </div>
                      </div> 
                    </div>
                  </div>
                  <div class="line"></div>
                  
                  <div class="flex">
                    <div class="flex-title">습도</div>

                    <div class="flex">
                      <div class="flex-content">
                        <div class="text_red weight">최고</div>
                        <div class="text_red"> {{ item.hum_highest }} %</div>
                      </div>

                      <div class="flex-content">
                        <div class="text_blue weight">최저</div>
                        <div class="text_blue"> {{ item.hum_lowest }} %</div>
                      </div>

                      <div class="flex-content">
                        <div class="weight">평균</div>
                        <div> {{ item.hum_average }} %  </div>
                      </div>
                    </div>  
                  </div>

                  <div class="line"></div>

                  <div class="flex">
                    <div class="flex-title">이산화탄소</div>

                    <div class="flex">
                      <div class="flex-content">
                        <div class="text_red weight">최고</div>
                        <div class="text_red"> {{ item.co2_highest }} ppm </div>
                      </div>

                      <div class="flex-content">
                        <div class="text_blue weight">최저</div>
                        <div class="text_blue"> {{ item.co2_lowest }} ppm  </div>
                      </div>

                      <div class="flex-content">
                        <div class="weight">평균</div>
                        <div> {{ item.co2_average }} ppm  </div>
                      </div>
                    </div>  
                  </div>

                  <div class="line"></div>
                  
                  <div class="flex">
                    <div class="flex-title">암모니아</div>

                    <div class="flex">
                      <div class="flex-content">
                        <div class="text_red weight">최고</div>
                        <div class="text_red"> {{ item.nh3_highest }} ppm </div>
                      </div>

                      <div class="flex-content">
                        <div class="text_blue weight">최저</div>
                        <div class="text_blue"> {{ item.nh3_lowest }} ppm  </div>
                      </div>

                      <div class="flex-content">
                        <div class="weight">평균</div>
                        <div> {{ item.nh3_average }} ppm  </div>
                      </div>
                    </div>  
                  </div>

                  <div class="line"></div>
                  
                  <div class="flex">
                    <div class="flex-title">황화수소</div>

                    <div class="flex">
                      <div class="flex-content">
                        <div class="text_red weight">최고</div>
                        <div class="text_red"> {{ item.h2s_highest }} ppm </div>
                      </div>

                      <div class="flex-content">
                        <div class="text_blue weight">최저</div>
                        <div class="text_blue"> {{ item.h2s_lowest }} ppm  </div>
                      </div>

                      <div class="flex-content">
                        <div class="weight">평균</div>
                        <div> {{ item.h2s_average }} ppm  </div>
                      </div>
                    </div>  
                  </div>
                </div>

              </td>
            </tr>

          </template>
        </v-data-table>


        <!-- 주간 데이터 조회   -->

        <v-card-title>
          <v-text-field dense style="width:300px" class="shrink mr-2"
            clearable
            v-model="search"
            append-icon="mdi-magnify"
            label="날짜 또는 시간으로 골라보기"
            single-line
            hide-details
          ></v-text-field>
          
          <v-btn class="report__btn mt-1" @click="Report()"  v-show="divisionTerm < 2">
            <v-icon size="medium">mdi-file-document</v-icon>
            엑셀다운
          </v-btn>
        </v-card-title>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import * as Xlsx from 'xlsx'
// import Apis from '@/api/apis'
// import Common from "@/utils/custom/common.js";

export default {
  name: 'ListDetail',
  props: {
    items: {
      type: Array,
    },
    items_daily: {
      type: Array,
    },
    loadingTable: Boolean,
    divisionTerm: Number,
  },

  created() {

  },

  data() {
    return {
      dialog: false,
      isMobile: false,
      search: "",
      headers: [
        { text: '측정일', align: 'left', value: 'checkDate', filterable: true, sortable: true, groupable: false, width: "130px", show: true},
        { text: '측정시간', align: 'left', value: 'checkTime', filterable: true, sortable: true, groupable: false, width: "100px",divider: true, show: true },
        { text: '온도 ℃', align: 'end', value: 'temp', filterable: false, sortable: true, groupable: false, width: "110px",divider: true, show: true },
        { text: '습도 %', align: 'end', value: 'hum', filterable: false, sortable: true, groupable: false, width: "110px", divider: true, show: true },
        { text: '체감온도 ℃', align: 'end', value: 'feel', filterable: false, sortable: true, groupable: false, width: "110px",divider: true, show: true  },
        { text: '이산화탄소 ppm', align: 'end', value: 'co2', filterable: false, sortable: true, groupable: false, width: "130px",divider: true , show: true },
        { text: '암모니아 ppm', align: 'end', value: 'nh3', filterable: false, sortable: true, groupable: false, width: "120px", show: true},
        { text: '황화수소 ppm', align: 'end', value: 'h2s', filterable: false, sortable: true, groupable: false, width: "120px", show: true },
      ],
      headers_daily: [
        { text: '측정일', align: 'left', value: 'checkDate', filterable: true, sortable: false, groupable: false, width: "130px", divider: true },
        { lb: true, text0: '온도 ℃',text: '최고', align: 'end', value: 'temp_highest', filterable: false, sortable: false, groupable: false, width: "60px",  divider: false },
        { text0: '',text: '최저', align: 'end', value: 'temp_lowest', filterable: false, sortable: false, groupable: false, width: "60px", divider: false },
        { text: '평균', align: 'end', value: 'temp_average', filterable: false, sortable: false, groupable: false, width: "60px", divider: true },
        { lb: true, text0: '체감 ℃',text: '평균', align: 'end', value: 'feel_average', filterable: false, sortable: false, groupable: false, width: "60px", divider: true },

        { lb: true, text0: '습도 %',text: '최고', align: 'end', value: 'hum_highest', filterable: false, sortable: false, groupable: false, width: "60px", divider: false },
        { text0: '',text: '최저', align: 'end', value: 'hum_lowest', filterable: false, sortable: false, groupable: false, width: "60px", divider: false },
        { text: '평균', align: 'end', value: 'hum_average', filterable: false, sortable: false, groupable: false, width: "60px", divider: true },

        { lb: true, text0: '이산화탄소',text: '최고', align: 'end', value: 'co2_highest', filterable: false, sortable: false, groupable: false, width: "62px",divider: false },
        { text0: 'ppm', text: '최저', align: 'end', value: 'co2_lowest', filterable: false, sortable: false, groupable: false, width: "62px", divider: false },
        { text: '평균', align: 'end', value: 'co2_average', filterable: false, sortable: false, groupable: false, width: "62px", divider: true },

        { lb: true, text0: '암모니아',text: '최고', align: 'end', value: 'nh3_highest', filterable: false, sortable: false, groupable: false, width: "62px", divider: false },
        { text0: 'ppm',text: '최저', align: 'end', value: 'nh3_lowest', filterable: false, sortable: false, groupable: false, width: "62px", divider: false },
        { text: '평균', align: 'end', value: 'nh3_average', filterable: false, sortable: false, groupable: false, width: "62px", divider: false },

        { lb: true, text0: '황화수소',text: '최고', align: 'end', value: 'h2s_highest', filterable: false, sortable: false, groupable: false, width: "62px",divider: false },
        { text0: 'ppm',text: '최저', align: 'end', value: 'h2s_lowest', filterable: false, sortable: false, groupable: false, width: "62px", divider: false },
        { text: '평균', align: 'end', value: 'h2s_average', filterable: false, sortable: false, groupable: false, width: "62px", divider: false },
      ],
    }
  },
  computed: {
  },
  methods: {
    selectRow(item,row) {
      row.select(true);
      // console.log("item-",item,"row=,",row)
    },
    onResize() {
      if (window.innerWidth < 769)
        this.isMobile = true;
      else
        this.isMobile = false;
    },
    Report(){
      let today = new Date();   
      let hours = today.getHours();
      let minutes = today.getMinutes();  

      if(this.divisionTerm < 2){
        const excelData = Xlsx.utils.table_to_sheet(document.getElementById('time__table')); // table id
        const workBook = Xlsx.utils.book_new(); // 새 시트 생성 
        Xlsx.utils.book_append_sheet(workBook, excelData, '지표데이터');  // 시트 명명, 데이터 지정
        Xlsx.writeFile(workBook, `시간 데이터_${hours}시 ${minutes}분.xlsx`); // 엑셀파일 만듬
      }else{
        const excelData = Xlsx.utils.table_to_sheet(document.getElementById('daily__table')); // table id를 넣어주면된다
        const workBook = Xlsx.utils.book_new(); // 새 시트 생성 
        Xlsx.utils.book_append_sheet(workBook, excelData, '지표데이터');  // 시트 명명, 데이터 지정
        Xlsx.writeFile(workBook, `일별데이터_${hours}시 ${minutes}분.xlsx`); // 엑셀파일 만듬
      }
    }
  },  // -----------methods 
}
</script>

<style lang="scss" scoped>
.v-data-table::v-deep{
  thead.v-data-table-header tr {
    th {
      background-color: #436251 !important;
      color: white !important;
      font-size:13px;
      height:40px !important;
    }
  }
}
.mobile {
  color: #333;
}
@media screen and (max-width: 768px) {
      .mobile table.v-table tr {
        max-width: 100%;
        position: relative;
        display: block;
      }

      .mobile table.v-table tr td {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #f5f5f5;
        height: auto;
        padding: 10px;
      }

      .mobile table.v-table tr td ul li:before {
        content: attr(data-label);
        padding-right: .5em;
        text-align: left;
        display: block;
        color: #999;
      }
      .v-datatable__actions__select
      {
        width: 50%;
        margin: 0px;
        justify-content: flex-start;
      }
      .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
        background: transparent;
      }

    }

.date{
  display:flex;
  justify-content: space-between !important;
  background-color: #436251;
  color:white;
  border-radius: 5px;
  padding:5px !important;
}
.flex{
  display:flex;
  flex-wrap:wrap;
  padding-top:3px;
  padding-bottom:3px;
}
.flex-content {
  justify-content: space-between !important;
  display: flex;
  flex-wrap: wrap;
  letter-spacing: -1.5px;
  width: 100%;
}
.flex-title{
  display:flex; 
  align-items: center !important;
  flex-wrap:wrap;
  font-weight:bold;
  width:70px;
}
.weight{
  letter-spacing: -1px;
  font-weight:600;
}
.line{
  width:100%;
  margin-top:3px;
  height:1px;
  border:0.5px solid #3a4f3f;
}
.text_blue{
  color: #3E4872;
}
.text_red{
  color:#88191a;
}
.text_sky{
  color:#617F8F;
}
.normal-btn{
  vertical-align: middle !important;
  color:white ;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.new-btn{
  color:#3a4f3f !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: rgb(248, 248, 248) !important;
}
.dialog-btn{
  color:white !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #88191a !important;
}
.cell_top{
  // text-align:right;
  // font-weight: bold;
  padding-left: 5px;
  font-size: 13px;
  color: white;
}
.cell_bottom{
  font-size: 13px;
  color: white !important;
  }
.cell_temp{
  font-weight: bold;
  text-align:right;
  background-color: #F3FCFC;
}

.cell_feel{
  font-weight: bold;
  text-align:right;
  font-weight: bold;
  background-color: #E2F0F0;
}
.cell_hum{
  // color:red;
  font-weight: bold;
  text-align:right;
  background-color: #F6F6F6;
}
.cell_co2{
  font-weight: bold !important;
  // color:red;
  text-align: right;
  background-color: #EAF4EF;
}
.cell_nh3{
  font-weight: bold;
  // color:red;
  text-align:center;
  background-color: #F0FCF5;
}
.cell_h2s{
  font-weight: bold;
  // color:red;
  text-align:center;
  background-color: #F9F9F1;
}
.cell_r{
  // color:red;
  text-align:right;
}
.cell_red{
  // color:red;
  color: white;
  text-align:right;
  background-color: rgb(253, 75, 52);
}
.select__title{
  font-size:20px;
  font-weight:600;
  letter-spacing: -1px;
  margin-bottom:10px;
}
.select__content{
  display:flex;
  flex-wrap: wrap;
  width:300px !important;
}
.indicators__title{
  max-width:65px !important;
  text-align: left !important;
  padding-top:20px;
  font-weight:700;
}
.exit__btn{
  background-color:white;
  color:#3a4f3f;
  letter-spacing: -1px;
  border-radius: 30px;
  min-width:95px !important;
  max-height:60px;
}
.report__btn{
  background-color:#3a4f3f !important;
  color:white;
  letter-spacing: -1px;
  border-radius: 30px;
  min-width:95px;
  max-height:60px;
}
</style>

