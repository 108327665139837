<template>
  <v-container>
    <div v-if="trendData.term < 2">
      <div class="axis__content">
        <div class="btn__content">
          <button title="온도" @click="selectY1('temp')" :class="{ active: y1Value === 'temp', temp_active: y1Value === 'temp' }">
            <img :src="icon_temp" class="icon"/>
          </button>
          <button title="외기온도" v-if="trendData.w_temp  && trendData.w_temp.length > 1"  @click="selectY1('w_temp')" :class="{ active: y1Value === 'w_temp', w_temp_active: y1Value === 'w_temp' }">
            <img :src="icon_outTemp" class="icon"/>
          </button>
          <button title="습도" @click="selectY1('hum')" :class="{ active: y1Value === 'hum', hum_active: y1Value === 'hum' }">
            <img :src="icon_hum" class="icon"/>
          </button>
          <button title="이산화탄소" v-if="trendData.co2 && trendData.co2.length > 1" @click="selectY1('co2')" :class="{ active: y1Value === 'co2' , co2_active: y1Value === 'co2' }">
            <img :src="icon_co2" class="icon"/>
          </button>
          <button title="암모니아" v-if="trendData.nh3  && trendData.nh3.length > 1" @click="selectY1('nh3')" :class="{ active: y1Value === 'nh3', nh3_active: y1Value === 'nh3' }">
            <img :src="icon_nh3" class="icon"/>
          </button>
          <button title="황화수소" v-if="trendData.h2s  && trendData.h2s.length > 1" @click="selectY1('h2s')" :class="{ active: y1Value === 'h2s', h2s_active: y1Value === 'h2s' }">
            <img :src="icon_h2s" class="icon"/>
          </button>
        </div>

        <div class="btn__content">
          <button title="온도" @click="selectY2('temp')" :class="{ active: y2Value === 'temp', temp_active: y2Value === 'temp'}">
            <img :src="icon_temp" class="icon"/>
          </button>
          <button  title="외기온도" v-if="trendData.w_temp  && trendData.w_temp.length > 1"  @click="selectY2('w_temp')" :class="{ active: y2Value === 'w_temp', w_temp_active: y2Value === 'w_temp'  }">
            <img :src="icon_outTemp" class="icon"/>
          </button>
          <button title="습도" @click="selectY2('hum')" :class="{ active: y2Value === 'hum', hum_active: y2Value === 'hum' }">
            <img :src="icon_hum" class="icon"/>
          </button>
          <button title="이산화탄소" v-if="trendData.co2 && trendData.co2.length > 1" @click="selectY2('co2')" :class="{ active: y2Value === 'co2' , co2_active: y2Value === 'co2'  }">
            <img :src="icon_co2" class="icon"/>
          </button>
          <button title="암모니아" v-if="trendData.nh3 && trendData.nh3.length > 1" @click="selectY2('nh3')" :class="{ active: y2Value === 'nh3', nh3_active: y2Value === 'nh3' }">
            <img :src="icon_nh3" class="icon"/>
          </button>
          <button title="황화수소" v-if="trendData.h2s && trendData.h2s.length > 1" @click="selectY2('h2s')" :class="{ active: y2Value === 'h2s', h2s_active: y2Value === 'h2s' }">
            <img :src="icon_h2s" class="icon"/>
          </button>
        </div>
      </div>

      <div class="chart__layout">
        <div class="axis__content mb_flex" style="padding:0 20px; margin-bottom:-10px;">
          <div style="margin-left:-10px;" class="label">{{ y1Name }}</div>
          <div class="label">{{ y2Name }}</div>
        </div>

        <line-chart :chart-data="chartData"  :styles="chartSize" :chart-options="options" :height="300" :width="100"></line-chart>
      </div>


      <div class="Dchart__layout bg_gray"
        v-if="trendData.temp && trendData.temp.length > 1" style="margin-top:50px;">
        <div class="mb-1"> 
            <div class="value_name">온도</div>
        </div>
        <line-chart
            id="TempChart"
            ref="TempChart"
            :chart-options="options"
            :chart-data="chartTemp"
            :height="150" 
        />
      </div>

      <div class="Dchart__layout bg_gray"
        v-if="trendData.w_temp && trendData.w_temp.length > 1" >
        <div class="mb-1"> 
            <div class="value_name">외기온도</div>
        </div>
        <line-chart
            id="wTempChart"
            ref="wTempChart"
            :chart-options="options"
            :chart-data="chartwTemp"
            :height="150" 
        />
      </div>

      <div class="Dchart__layout bg_gray"
        v-if="trendData.hum && trendData.hum.length > 1">
        <div class="mb-1"> 
            <div class="value_name">습도</div>
        </div>
        <line-chart
            id="HumChart"
            ref="HumChart"
            :chart-options="options"
            :chart-data="chartHum"
            :height="150" 
        />
      </div>

      <div class="Dchart__layout bg_gray"
        v-if="trendData.co2 && trendData.co2.length > 1">
        <div class="mb-1"> 
            <div class="value_name">이산화탄소</div>
        </div>
        <line-chart
            id="Co2Chart"
            ref="Co2Chart"
            :chart-options="options"
            :chart-data="chartCo2"
            :height="150" 
        />
      </div>

      <div class="Dchart__layout bg_gray"
        v-if="trendData.nh3 && trendData.nh3.length > 1">
        <div class="mb-1"> 
            <div class="value_name">암모니아</div>
        </div>
        <line-chart
            id="Nh3Chart"
            ref="Nh3Chart"
            :chart-options="options"
            :chart-data="chartNh3"
            :height="150" 
        />
      </div>

      <div class="Dchart__layout bg_gray"
        v-if="trendData.h2s && trendData.h2s.length > 1">
        <div class="mb-1"> 
            <div class="value_name">황화수소</div>
        </div>
        <line-chart
            id="H2sChart"
            ref="H2sChart"
            :chart-options="options"
            :chart-data="chartH2s"
            :height="150" 
        />
      </div>



    </div>

    <div v-else>
      <div class="axis__content">
        <div class="btn__content">
          <button @click="selectY1('temp')" :class="{ active: y1Value === 'temp', temp_active: y1Value === 'temp'  }">
            <img :src="icon_temp" class="icon"/>
          </button>
          <button  v-if="trendData.w_temp  && trendData.w_temp.length > 1"  @click="selectY1('w_temp')" :class="{ active: y1Value === 'w_temp', w_temp_active: y1Value === 'w_temp' }">
            <img :src="icon_outTemp" class="icon"/>
          </button>
          <button @click="selectY1('hum')" :class="{ active: y1Value === 'hum', hum_active: y1Value === 'hum'  }">
            <img :src="icon_hum" class="icon"/>
          </button>
          <button v-if="trendData.co2_time && trendData.co2_time.length > 1" @click="selectY1('co2')" :class="{ active: y1Value === 'co2', co2_active: y1Value === 'co2' }">
            <img :src="icon_co2" class="icon"/>
          </button>
          <button v-if="trendData.nh3_time  && trendData.nh3_time.length > 1" @click="selectY1('nh3')" :class="{ active: y1Value === 'nh3', nh3_active: y1Value === 'nh3' }">
            <img :src="icon_nh3" class="icon"/>
          </button>
          <button v-if="trendData.h2s_time  && trendData.h2s_time.length > 1" @click="selectY1('h2s')" :class="{ active: y1Value === 'h2s', h2s_active: y1Value === 'h2s' }">
            <img :src="icon_h2s" class="icon"/>
          </button>
        </div>

        <div class="btn__content">
          <button @click="selectY2('temp')" :class="{ active: y2Value === 'temp', temp_active: y2Value === 'temp' }">
            <img :src="icon_temp" class="icon"/>
          </button>
          <button  v-if="trendData.w_temp  && trendData.w_temp.length > 1"  @click="selectY2('w_temp')" :class="{ active: y2Value === 'w_temp', w_temp_active: y2Value === 'w_temp' }">
            <img :src="icon_outTemp" class="icon"/>
          </button>
          <button @click="selectY2('hum')" :class="{ active: y2Value === 'hum', hum_active: y2Value === 'hum' }">
            <img :src="icon_hum" class="icon"/>
          </button>
          <button v-if="trendData.co2_time && trendData.co2_time.length > 1" @click="selectY2('co2')" :class="{ active: y2Value === 'co2',co2_active: y2Value === 'co2' }">
            <img :src="icon_co2" class="icon"/>
          </button>
          <button v-if="trendData.nh3_time && trendData.nh3_time.length > 1" @click="selectY2('nh3')" :class="{ active: y2Value === 'nh3',nh3_active: y2Value === 'nh3' }">
            <img :src="icon_nh3" class="icon"/>
          </button>
          <button v-if="trendData.h2s_time && trendData.h2s_time.length > 1" @click="selectY2('h2s')" :class="{ active: y2Value === 'h2s',h2s_active: y2Value === 'h2s'  }">
            <img :src="icon_h2s" class="icon"/>
          </button>
        </div>
      </div>

      <div class="chart__layout">
        <div class="axis__content mb_flex" style="padding:0 20px; margin-bottom:-10px;">
          <div style="margin-left:-10px;" class="label">{{ y1Name }}</div>
          <div class="label">{{ y2Name }}</div>
        </div>

        <line-chart :chart-data="dailyChartData"  :styles="chartSize" :chart-options="options" :height="300" :width="100"></line-chart>
      </div>
      
      <div class="Dchart__layout bg_gray"
        v-if="trendData.temp_time && trendData.temp_time.length > 1" style="margin-top : 50px;">
        <div class="value_name">온도</div>
        <LineChart
            :chart-options="Dailyoptions"
            :chart-data="dailyChartTemp"
            :height="200"
        />
        </div>

      <div class="Dchart__layout bg_gray"
        v-if="trendData.w_temp_avg && trendData.w_temp_avg.length > 1">
        <div class="value_name">외기온도</div>
        <LineChart
            :chart-options="Dailyoptions"
            :chart-data="dailyChartwTemp"
            :height="200"
        />
      </div>

        <div class="Dchart__layout bg_gray"
          v-if="trendData.hum_time && trendData.hum_time.length > 1">
          <div class="value_name">습도</div>
          <LineChart
              :chart-options="Dailyoptions"
              :chart-data="dailyChartHum"
              :height="200"
          />
        </div>

        <div class="Dchart__layout bg_gray"
            v-if="trendData.co2_time && trendData.co2_time.length> 1">
            <div class="value_name">이산화탄소</div>
            <LineChart
                :chart-options="Dailyoptions"
                :chart-data="dailyChartCo2"
                :height="200"
            />
        </div>

        <div class="Dchart__layout bg_gray"
            v-if="trendData.nh3_time && trendData.nh3_time.length> 1">
            <div class="value_name">암모니아</div>
            <LineChart
                :chart-options="Dailyoptions"
                :chart-data="dailyChartNh3"
                :height="200"
            />
        </div>

        <div class="Dchart__layout bg_gray"
            v-if="trendData.h2s_time && trendData.h2s_time.length> 1">
            <div class="value_name">황화수소</div>
            <LineChart
                :chart-options="Dailyoptions"
                :chart-data="dailyChartH2s"
                :height="200"
            />
        </div>
      </div>
  </v-container>
</template>

<script>
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, LinearScale, CategoryScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, LinearScale, CategoryScale);

export default {
  name: 'ChartTest',
  components: {
    LineChart: Line
  },
  props: {
    trendData: {
      type: Object,
    },
  },
  computed: {
    chartData() {
      const datasets = [];

      if (this.y1Value) {
        datasets.push({
          label: this.getLabel(this.y1Value),
          data: this.trendData[this.y1Value],
          borderColor: this.getColor(this.y1Value),
          fill: false,
          yAxisID: 'y1'
        });
      }

      if (this.y2Value) {
        datasets.push({
          label: this.getLabel(this.y2Value),
          data: this.trendData[this.y2Value],
          borderColor: this.getColor(this.y2Value),
          fill: false,
          yAxisID: 'y2'
        });
      }
      
      return {
        labels: this.trendData.time_temp,
        datasets
      };
    },
    dailyChartData(){
      const datasets = [];

      if (this.y1Value) {
        datasets.push({
          label: this.getLabel(this.y1Value + '_array'),
          data: this.trendData[this.y1Value + '_avg'],
          borderColor: this.getColor(this.y1Value),
          fill: false,
          yAxisID: 'y1'
        });
      }

      if (this.y2Value) {
        datasets.push({
          label: this.getLabel(this.y2Value + '_array'),
          data: this.trendData[this.y2Value + '_avg'],
          borderColor: this.getColor(this.y2Value),
          fill: false,
          yAxisID: 'y2'
        });
      }
      
      return {
        labels: this.trendData.time_array,
        datasets
      };
    },
    y1Name() {
      switch (this.y1Value) 
      {
        case 'temp': return '온도 ℃';
        case 'w_temp': return '외기온도 ℃';
        case 'hum': return '습도 %';
        case 'co2': return '이산화탄소 ppm';
        case 'nh3': return '암모니아 ppm';
        case 'h2s': return '황화수소 ppm';
        default: return '';
      }
    },
    y2Name() {
      switch (this.y2Value) 
      {
        case 'temp': return '온도 ℃';
        case 'w_temp': return '외기온도 ℃';
        case 'hum': return '습도 %';
        case 'co2': return '이산화탄소 ppm';
        case 'nh3': return '암모니아 ppm';
        case 'h2s': return '황화수소 ppm';
        default: return '';
      }
    },
  },
  watch:{
    chartData: {
      handler(newVal, oldVal) {
        if (this.animationCompleted) {
          this.options.animation.duration = 0;
        } else {
          this.animationCompleted = true;
        }
      },
      deep: true
    },
    trendData(){
      this.dailyChartTemp = this.dailyChart(
      this.trendData.temp_avg,
      this.trendData.temp_high,
      this.trendData.temp_low,
      '#8CC0DE',
      "rgba(198, 252, 236, 0.1)"
    );

    this.dailyChartwTemp = this.dailyChart(
      this.trendData.w_temp_avg,
      this.trendData.w_temp_high,
      this.trendData.w_temp_low,
      '#51829B',
      "rgba(198, 252, 236, 0.1)"
    );

    this.dailyChartHum = this.dailyChart(
      this.trendData.hum_avg,
      this.trendData.hum_high,
      this.trendData.hum_low,
      '#CCEEBC',
      "rgba(249, 235, 151, 0.1)"
    );

    this.dailyChartCo2 = this.dailyChart(
      this.trendData.co2_avg,
      this.trendData.co2_high,
      this.trendData.co2_low,
      '#8EDBE7',
      "rgba(168, 217, 246, 0.1)"
    );

    this.dailyChartNh3 = this.dailyChart(
      this.trendData.nh3_avg,
      this.trendData.nh3_high,
      this.trendData.nh3_low,
      '#FFD6A5',
      "rgba(26, 101, 158, 0.1)"
    );
    // console.log(this.trendData.h2s_avg)
    this.dailyChartH2s = this.dailyChart(
      this.trendData.h2s_avg,
      this.trendData.h2s_high,
      this.trendData.h2s_low,
      '#D4E2D4',
      "rgba(108, 208, 168, 0.1)"
    );

    this.chartTemp = this.TimeChart(
      this.trendData.time_temp,
      this.trendData.temp,
      '#8CC0DE',
    );

    this.chartwTemp = this.TimeChart(
      this.trendData.time_temp,
      this.trendData.w_temp,
      '#51829B',
    );

    this.chartHum = this.TimeChart(
      this.trendData.time_hum,
      this.trendData.hum,
      '#CCEEBC',
    );

    this.chartCo2 = this.TimeChart(
      this.trendData.time_co2,
      this.trendData.co2,
      '#8EDBE7',
    );

    this.chartNh3 = this.TimeChart(
      this.trendData.time_nh3,
      this.trendData.nh3,
      '#FFD6A5',
    );

    this.chartH2s = this.TimeChart(
      this.trendData.time_h2s,
      this.trendData.h2s,
      '#D4E2D4',
    );
    
    }
  },
  data() {
    return {
      icon_temp: require("@/assets/monitoring/icon/temp.svg"),
      icon_hum: require("@/assets/monitoring/icon/humidity.svg"),
      icon_co2: require("@/assets/monitoring/icon/co2.svg"),
      icon_nh3: require("@/assets/monitoring/icon/nh3.svg"),
      icon_h2s: require("@/assets/monitoring/icon/h2s.svg"),
      icon_outTemp: require("@/assets/monitoring/icon/outside_temp.svg"),

      chartTemp: { },
      chartHum: { },
      chartCo2: { },
      chartNh3: { },
      chartH2s: { },

      dailyChartTemp: { },
      dailyChartHum: { },
      dailyChartCo2: { },
      dailyChartNh3: { },
      dailyChartH2s: { },
      
      chartSize: {
        height: '250px',
        width: '100%',
        padding:'20px',
        position: 'relative',
      },

      y1Value_daily: 'temp',
      y2Value_daily: 'hum',

      y1Value: 'temp',
      y2Value: 'hum',

      options:{
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 2000
        },
        scales: {
            x: {
              grid: {
                  drawOnChartArea: false // 이 축의 그리드 라인은 숨기기
              },
              ticks: {
                  // 보이는 갯수 제한하기 autoSkip, maxTrickLimit 
                  autoSkip: true,
                  maxTicksLimit:5,
                  maxRotation: 0,
                  minRotation: 0,
                  display: true,
                  padding: 3,
                  font: {
                      size: 9,
                  }
              }
            },
            'y1': {
                type: 'linear',
                position: 'left',
                // textStrokeWidth:100,
                ticks: {
                    beginAtZero: true,
                    suggestedMax: 100,
                },
                afterFit: function(scale) {
                    scale.width = 40; // Set the desired width
                }
            },
            'y2': {
                type: 'linear',
                position: 'right',
                ticks: {
                    beginAtZero: true,
                    suggestedMax: 40,
                    
                },
                title: {
                    display: true,
                },
                grid: {
                    drawOnChartArea: false // 이 축의 그리드 라인은 숨기기
                },
                afterFit: function(scale) {
                    scale.width = 40; // Set the desired width
                }
            }
        },
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
            },
        elements: {
            line: {	/* 선 옵션 */
                borderCapStyle: 'round',
                borderDashOffset: 0,
                borderJoinStyle: 'round',	
                borderWidth: 3,
                capBezierPoints: true,
                // fill: true,
                tension: 0.4
            },
            point: {	/* 표시자 옵션 */
                pointStyle: 'rectRounded',
                pointRadius: 0, 
                lineTension:1, 
                pointBorderWidth: 0, 
            },
        },
        plugins: {
          legend: {
              display: false,
            },
            tooltip: {
                y: [{
                backgroundColor: '#ACB8C0',
                caretSize: 4,
                cornerRadius: 6,
                borderWidth: 0,
                bodyFont: {
                    size: 12
                }
                }]
            },
            hover: {
                animationDuration: 400,
                axis: 'x',
                intersect: true,
                mode: 'label',
                onHover: null
            },
        },
    },
    Dailyoptions: {
      elements: {
        line: {	/* 선 옵션 */
            borderCapStyle: 'round',	/* 선 마감: butt, round, square */
            borderDashOffset: 0,
            borderJoinStyle: 'round',	/* 꺾이는 부분 모양: miter, bevel, round */
            borderWidth: 3.0,
            capBezierPoints: true,
            fill: true,	/* 선 밑 공간 색으로 채우기 */
            tension: 0.4
        },
        point: {	/* 표시자 옵션 */
            pointStyle: 'circle',	/* circle, cross, corssRot, dash, line, rect, rectRounded, rectRot, start, triangle */
            pointRadius: 2, //point 없게 만들기 0으로 설정
            lineTension:1, //선 곡선모양 0이면 직선
            pointBorderWidth: 2, //포인터 보더사이즈
        },
      },
      plugins: {
        legend: {
            display: false,
            position: "top",
            labels: {
                boxWidth: 1,
                padding: 5,
                pointStyle: "circle",
                font: {
                    size: 13
                }
            },
            fullSize: true,
            align: "left"
        },
        tooltip: {
            backgroundColor: '#ACB8C0',
            caretSize: 4,
            cornerRadius: 6,
            borderWidth: 0,
            bodyFont: {
                size: 12
            }
        },
        hover: {
            animationDuration: 400,
            axis: 'x',
            intersect: true,
            mode: 'label',
            onHover: null
        },
      },
      scales: {
        x: {
            grid: {
                display: true,
            },
            ticks: {
                display:true,
                padding: 3,
                font: {
                    size: 12,
                }
            },
        },
        y: {
            afterFit(scale) {       // ticks 표시영역 폭 통일 
                scale.width = 50;
            },
            ticks: {
                display:true,
                padding: 5,
                font: {
                    size: 10,
                }
            },
        // y축 스타일 지정
        grid: {
            drawBorder: true,
            color: "#e5e5e5",
            lineWidth: 1
        },
        fontSize:9,
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      animation: {
          duration: 1000
        },
      },
    }
  },
  methods: {
    selectY1(value) {
      this.y1Value = value;
    },
    selectY2(value) {
      this.y2Value = value;
    },

    getLabel(value) {
      switch (value) {
        case 'temp': return '온도 ℃';
        case 'w_temp': return '외기온도 ℃';
        case 'hum': return '습도 %';
        case 'co2': return '이산화탄소 ppm';
        case 'nh3': return '암모니아 ppm';
        case 'h2s': return '황화수소 ppm';
        default: return '';
      }
    },
    getColor(value) {
      switch (value) {
        case 'temp': return '#8CC0DE';
        case 'w_temp': return '#51829B';
        case 'hum': return '#CCEEBC';
        case 'co2': return '#8EDBE7';
        case 'nh3': return '#FFD6A5';
        case 'h2s': return '#D4E2D4';
        default: return '';
      }
    },

    TimeChart(label,trendData,bgColor){
      return{
        labels:label,
        datasets:[
          {
          data:trendData,
          borderColor:bgColor
          }
        ]
      }
    },

    dailyChart(avgData, highData, lowData, avgColor, bgColor){
      return {
        labels: this.trendData.time_array,
        datasets: [
        {
          label: '평균',
          data: avgData,
          borderColor: avgColor,
          backgroundColor: bgColor,
        },
        {
          label: '최고',
          data: highData,
          fill: false,
          borderColor: "rgba(124, 9, 2, 0.4)",
          tension: 0.1,
          borderDash: [3, 3],
        },
        {
          label: '최저',
          data: lowData,
          fill: false,
          borderColor: "rgba(93, 138, 168, 0.4)",
          tension: 0.1,
          borderDash: [3, 3],
        }
      ]
      }
    },
    
  }
};
</script>

<style scoped>
button {
  margin-right: 5px;
  padding: 5px 5px;
  border-radius: 10px;
  width:50px;
  /* border: 1px solid #3a4f3f; */
  color:#3a4f3f;
  /* color: white; */
  cursor: pointer;
}
button:hover {
  background-color:#eaeaea;
  transition: all 0.5s ease;
}
button.active {
  /* background-color: #3a4f3f; */
  color:white;
  border:none;
}
.active .icon{
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(202deg) brightness(106%) contrast(101%);
}
.icon{
  margin-top:3px;
  width: 30px;
  height: 25px;
  filter: invert(25%) sepia(12%) saturate(894%) hue-rotate(82deg) brightness(100%) contrast(88%);
}
.bg_gray{
  background-color:#fbfbfb;
}
.axis__content {
  display: flex;
  justify-content: space-between;
}
.chart__layout{
  /* position: relative; */
  height: 280px !important; 
  width:100%;
  padding:10px;
  margin-top:20px;
  border:1px solid #e5e5e5;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  /* background-color:#ffffff; */
  border-radius: 20px;
}
.Dchart__layout{
  height:210px !important; 
  width:100%;
  padding:10px;
  margin-top:10px;
  border:1px solid #e5e5e5;
  border-radius: 20px;
  padding-left: 20px;
}
.label{
  background-color:#FFF9D0;
  color:#3a4f3f;
  padding:5px;
  border-radius: 10px;
  font-weight:600;
}
.value_name{
  font-size:17px;
  font-weight:600;
  margin-bottom: 10px;
}
.btn__content{
  display: flex;
  align-items: center;
}
.chart__layout{
  max-height:500px;
}

.temp_active{
  background-color:#8CC0DE
}
.w_temp_active{
  background-color:#51829B
}
.hum_active{
  background-color:#CCEEBC
}
.co2_active{
  background-color:#8EDBE7
}
.nh3_active{
  background-color:#FFD6A5
}
.h2s_active{
  background-color:#D4E2D4
}
@media screen and (max-width: 768px){
  .axis__content{
    flex-direction: column; 

  }
  .btn__content{
    margin-top:5px;
    justify-content: center;
  }
  .mb_flex{
    flex-direction: row;
  }
}

</style>
